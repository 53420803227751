import * as React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";
import icon from "../assets/icons/checkmark-success-blue.svg";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: "#309CD0",
    color: "white",
    width: "100%",
  },
  header: {
    whiteSpace: "pre-line",
    textAlign: "center",
    margin: "15px 30px 35px",
  },
  img: {
    width: "30px",
    marginTop: "30px",
  },
}));

export default function ResetSuccess(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();
  useUpdateWindowHeight();

  const handleLoginClick = () => navigateTo("login");

  return (
    <Box display="flex" style={{ flexFlow: "column" }} alignItems="center">
      <img src={icon} alt="" className={classes.img} />
      <h3 className={classes.header}>
        {t("forgotPasswordForm.resetSuccess.title")}
      </h3>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleLoginClick}
      >
        {t("forgotPasswordForm.buttons.login")}
      </Button>
    </Box>
  );
}
