import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import icon from "../../assets/icons/not-successful.svg";

const useStyles = makeStyles({
  subTitle: {
    marginBottom: "20px",
    textAlign: "center",
    lineHeight: "18px",
  },
  link: {
    color: "#309CD0 !important",
    textDecorationStyle: "solid",
  },
  button: {
    backgroundColor: "#309CD0",
    borderColor: "#309CD0",
    padding: "10px",
    borderRadius: "20px",
    width: "200px",
    textAlign: "center",
    margin: "10px",
    color: "white",
  },
  img: {
    width: "30px",
    marginTop: "40px",
  },
});

interface IVerifyError {
  serverValidationErrorKey: string;
}

export default function VerifyError({
  serverValidationErrorKey,
}: IVerifyError): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      style={{
        flexFlow: "column",
        alignItems: "center",
        marginLeft: "0px",
        marginRight: "0px",
        maxWidth: "290px",
      }}
    >
      <img src={icon} alt="" className={classes.img} />
      <h3 style={{ textAlign: "center" }}>
        {serverValidationErrorKey === ""
          ? t("verify.error.link")
          : t(`serverValidation.${serverValidationErrorKey}`)}
      </h3>
      <span className={classes.subTitle} style={{ color: "#7A7C83" }}>
        {t("verify.error.subTitle")}
      </span>
      <a
        href="https://vendor.com.mk/customer-service/"
        className={classes.link}
      >
        {t("verify.error.link")}
      </a>
    </Box>
  );
}
