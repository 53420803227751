import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import icon from "../../assets/icons/checkmark-success-blue.svg";

const useStyles = makeStyles({
  subTitle: {
    marginBottom: "20px",
    textAlign: "center",
    lineHeight: "18px",
  },
  link: {
    color: "#309CD0 !important",
    textDecoration: "underline !important",
  },
  button: {
    backgroundColor: "#309CD0",
    borderColor: "#309CD0",
    padding: "10px",
    borderRadius: "20px",
    width: "200px",
    textAlign: "center",
    margin: "10px",
    color: "white",
    cursor: "pointer",
  },
  img: {
    width: "30px",
    marginTop: "40px",
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function VerifySuccess({ callback }: any): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const domain = callback ? callback.split("://")[1] : "";

  const handleButtonClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location.href = process.env.REACT_APP_VENDOR_DASHBOARD;
  };

  return (
    <Box
      display="flex"
      style={{
        flexFlow: "column",
        alignItems: "center",
        marginLeft: "0px",
        marginRight: "0px",
        minWidth: "300px",
      }}
    >
      <img src={icon} alt="" className={classes.img} />
      <h3 style={{ textAlign: "center" }}>{t("verify.success.title")}</h3>
      <span className={classes.subTitle} style={{ color: "#7A7C83" }}>
        {t("verify.success.subTitle")}
      </span>
      {callback && (
        <a
          href={callback}
          className={classes.link}
          style={{ textDecoration: "underline !important" }}
        >
          {t("verify.success.link", { domain })}
        </a>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      {/* <div className={classes.button} onClick={handleButtonClick}>
        {t("verify.success.button")}
      </div> */}
    </Box>
  );
}
