/* eslint-disable */
import * as React from "react";
import { TextField, Select } from "mui-rff";
import {
  Box,
  makeStyles,
  MenuItem,
  InputAdornment,
  ClickAwayListener,
  TextField as TextInput,
} from "@material-ui/core";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";
import phoneIcon from "../assets/icons/icon-blue-phone.svg";
import { formatPhoneNumber, phoneParser } from "../utils/parsers";
import phoneData from "../assets/jsons/completePhoneList.json";
import { useForm, useFormState } from "react-final-form";
import _toString from "lodash/toString";
import _get from "lodash/get";
import _isNil from "lodash/isNil";
import { lowerCase } from "lodash";

interface IPhoneInput {
  fieldProps?: any;
  name: string;
  placeholder: string;
  className?: string;
}

const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "50px",
    width: "100%",
  },
  prefix: {
    width: "70px",
    position: "absolute",
    left: "25px",
    "&:before": {
      border: "0 !important",
    },
    "&:after": {
      border: "0 !important",
    },
  },
  number: {
    position: "absolute",
    left: 0,
    top: 0,
    "& input": {
      marginLeft: "75px !important",
    },
  },
});

export default function PhoneInput({
  fieldProps,
  name,
  className,
  ...rest
}: IPhoneInput) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState("");

  const validationMessage = "Невалиден формат (07XXXXXXX)";
  const validationDigitNumberMessage = "Бројот треба да има 9 цифри";

  const formApi = useForm();
  const { errors, submitFailed } = useFormState();
  const currentFieldFormError = _get(errors, `${name}Number`);

  const [filter, setFilter] = React.useState<string>("");

  const handleChange = (e: any) => {
    const numberInput = e.target.value;
    const parsedValue = formatPhoneNumber(numberInput);
    formApi.change(`${name}Number`, parsedValue);

    if (
      numberInput.length > 1 &&
      _toString(numberInput[0]) === "0" &&
      _toString(numberInput[1]) !== "7"
    ) {
      setErrorMessage(validationMessage);
    } else if (
      _toString(numberInput[0]) === "0" ||
      _toString(numberInput[0]) === "7"
    ) {
      setErrorMessage("");
    } else {
      setErrorMessage(validationMessage);
    }
  };

  const validatePhoneInput = React.useCallback(
    (e) => {
      const phoneInput = e.target.value;
      const trimmedFromDashes = phoneInput
        ? phoneInput.replace(/-/g, "")
        : phoneInput;

      if (
        errorMessage === "" &&
        trimmedFromDashes.length > 0 &&
        trimmedFromDashes.length < 9
      ) {
        setErrorMessage(validationDigitNumberMessage);
      } else {
        setErrorMessage("");
      }
    },
    [setErrorMessage]
  );

  const [countries, setCountries] = React.useState<any[]>([]);
  React.useEffect(() => {
    const data = Object.keys(phoneData).map((countryCode) => ({
      countryCode,
      // @ts-ignore
      phonePrefix: phoneData[countryCode],
    }));
    // @ts-ignore
    setCountries(data);
  }, [phoneData]);

  const filteredCountries = React.useMemo(() => {
    return countries.filter((c) =>
      c.phonePrefix.replace(/\W/g, "").startsWith(filter.replace(/\W/g, ""))
    );
  }, [filter, countries]);

  return (
    <Box className={`${classes.container} ${className}`}>
      <TextField
        name={`${name}Number`}
        onBlur={validatePhoneInput}
        {...rest}
        fieldProps={{ ...fieldProps, parse: formatPhoneNumber }}
        className={`${classes.number} ${className}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={phoneIcon} alt="" />
            </InputAdornment>
          ),
        }}
        error={
          errorMessage !== "" ||
          (!_isNil(currentFieldFormError) && submitFailed)
        }
        helperText={errorMessage}
        // @ts-ignore
        onChange={handleChange}
      />
      <Select name={`${name}Prefix`} className={classes.prefix}>
        <Box
          position="sticky"
          top={0}
          style={{
            backgroundColor: "white",
          }}
          zIndex={999}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          p={2}
        >
          <ClickAwayListener onClickAway={() => null}>
            <TextInput
              onKeyDown={(e) => {
                // Prevent MUI-Autoselect while typing
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              placeholder="Filter"
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              autoFocus
            />
          </ClickAwayListener>
        </Box>
        {filteredCountries.map((c) => {
          return (
            // @ts-ignore
            <MenuItem value={c.phonePrefix} key={c.countryCode}>
              <Box display="flex" alignItems="center">
                {/* @ts-ignore*/}
                {/* <ReactCountryFlag countryCode={c.countryCode} /> */}
                <img
                  src={`https://flagcdn.com/80x60/${lowerCase(
                    c.countryCode
                  )}.png`}
                  style={{
                    width: 15,
                  }}
                />
                {/* @ts-ignore*/}
                <Box style={{ marginLeft: "5px" }}>{c.phonePrefix}</Box>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
