import { makeVendorCoreClient } from "../client";

export function authRegister(requestBody: Record<string, unknown>): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .register_api_v1_auth_register__post(
        {
          token_lifetime: 86400,
        },
        {
          requestBody,
        }
      )
      .then((r: any) => r);
  });
}

export function authLogin(requestBody: Record<string, unknown>): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .login_api_v1_auth_login__post(
        {
          token_lifetime: 86400,
        },
        {
          requestBody,
        }
      )
      .then((r: any) => r);
  });
}

export function authVerify(requestBody: Record<string, any>): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .verify_api_v1_auth_verify__post(null, {
        requestBody,
      })
      .then((r: any) => r);
  });
}

export function getPasswordResetCode(requestBody: {
  phone_number: string;
  email: any;
  callback_url?: string;
}): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .get_password_reset_code_api_v1_auth_get_password_reset_code__post(null, {
        requestBody,
      })
      .then((r: any) => r);
  });
}

export function validateResetCode(
  requestBody: Record<
    string,
    {
      otp: string;
      email: string;
      phone_number: string;
    }
  >
): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .validate_reset_code_api_v1_auth_validate_reset_code__post(null, {
        requestBody,
      })
      .then((r: any) => r);
  });
}

export function resetPassword(
  requestBody: Record<
    string,
    {
      email: string;
      phone_number: string;
      token: string;
      new_password: string;
    }
  >
): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .reset_password_api_v1_auth_reset_password__post(null, {
        requestBody,
      })
      .then((r: any) => r);
  });
}
export function changePassword(
  requestBody: Record<
    string,
    {
      old_password: string;
      new_password: string;
      email: string;
    }
  >
): any {
  return makeVendorCoreClient().then((client: any) => {
    return client.apis.v1
      .change_password_api_v1_auth_change_password__post(null, {
        requestBody,
      })
      .then((r: any) => r);
  });
}
