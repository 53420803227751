import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Box, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import logo from "./assets/icons/multipass-badge.svg";
import Verify from "./pages/verify/Verify";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ContactForm from "./pages/ContactForm";
import EnterCode from "./pages/EnterCode";
import ResetPassword from "./pages/ResetPassword";
import ResetSuccess from "./pages/ResetSuccess";
import useUpdateWindowHeight from "./utils/window";
import WithResetPasswordData from "./contexts/ResetPasswordContactContext";
import ForgotPasswordVerify from "./pages/ForgotPasswordVerify";

const useStyles = makeStyles({
  img: {
    width: "230px",
    height: "105px",
  },
});

function App(): React.ReactElement {
  const classes = useStyles();
  useUpdateWindowHeight();

  return (
    <ThemeProvider theme={theme}>
      <WithResetPasswordData>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding="20px"
        >
          {/* <img src={logo} alt="" className={classes.img} /> */}
          <Box minWidth="300px" display="flex" justifyContent="center">
            <Router>
              <Switch>
                <Route path="/verify/:token/:locale" component={Verify} />
                <Route path="/register/:locale" component={Register} />
                <Route path="/login/:locale" component={Login} />
                <Route
                  path="/forgot-password/email/:locale"
                  component={ContactForm}
                  strict
                />
                <Route
                  path="/forgot-password/phone/:locale"
                  component={ContactForm}
                />
                <Route
                  path="/forgot-password/enter-code/:locale"
                  component={EnterCode}
                />
                <Route
                  path="/forgot-password/verify/:token/:locale"
                  component={ForgotPasswordVerify}
                />
                <Route
                  path="/forgot-password/:locale"
                  component={ForgotPassword}
                />
                <Route
                  path="/reset-password/:locale"
                  component={ResetPassword}
                />
                <Route path="/reset-success/:locale" component={ResetSuccess} />
                <Route path="/:locale" component={Home} />
              </Switch>
            </Router>
          </Box>
        </Box>
      </WithResetPasswordData>
    </ThemeProvider>
  );
}

export default App;
