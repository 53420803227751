import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { Box, Button, InputAdornment, makeStyles } from "@material-ui/core";
import { makeValidate, Checkboxes } from "mui-rff";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import emailIcon from "../assets/icons/icon-blue-email.svg";
import passwordIcon from "../assets/icons/icon-blue-lock.svg";
import LineThroughLabel from "../components/LineThroughLabel";
import PasswordInput from "../form/PasswordInput";
import { authLogin } from "../api/vendor-core/v1";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";
import { ResetPasswordContactContext } from "../contexts/ResetPasswordContactContext";
import EmailInput from "../form/EmailInput";

const useStyles = makeStyles({
  input: {
    margin: "4px 0",
    minHeight: "50px",
  },
  lightPrimaryButton: {
    color: "#309CD0",
    borderColor: "#309CD0",
  },
  icon: {
    color: "#309CD0",
  },
});

export default function LoginForm(): React.ReactElement {
  const classes = useStyles();
  useUpdateWindowHeight();
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setResetPasswordData } = React.useContext(
    ResetPasswordContactContext
  );

  React.useEffect(() => {
    setResetPasswordData({});
  }, [setResetPasswordData]);

  const fields = [
    {
      name: "email",
      component: EmailInput,
      placeholder: t("loginForm.email"),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <img src={emailIcon} alt="" />
          </InputAdornment>
        ),
      },
      className: classes.input,
    },
    {
      name: "password",
      component: PasswordInput,
      placeholder: t("loginForm.password"),
      InputProps: {
        type: "password",
        startAdornment: (
          <InputAdornment position="start">
            <img src={passwordIcon} alt="" />
          </InputAdornment>
        ),
      },
      className: classes.input,
    },
    {
      name: "rememberMe",
      component: Checkboxes,
      data: { label: t("loginForm.remember"), value: true },
    },
  ];
  // fix access
  const handleLogin = async (values: any) => {
    const { email, password, rememberMe } = values;
    try {
      const response = await authLogin({ email, password });
      if (response.ok) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.parent) {
          window.parent.postMessage(
            { access_token: response.body.access_token, rememberMe },
            "*"
          );
        }
      }
      return;
    } catch (e: any) {
      if (e?.response?.obj.detail) {
        // eslint-disable-next-line consistent-return
        return {
          password: t(`serverValidation.${e?.response?.obj?.detail}`),
        };
      }
    }
  };

  const schema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string().required(t("loginForm.validation.email")),
      password: Yup.string().required(t("loginForm.validation.password")),
    });
  }, [t]);

  const validate = makeValidate(schema);
  const handleRegisterClick = () => {
    navigateTo("register");
  };

  return (
    <Form
      validate={validate}
      onSubmit={handleLogin}
      render={({ handleSubmit }: any): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              marginLeft: "40px",
              marginRight: "40px",
              minWidth: "300px",
            }}
          >
            <Box
              display="flex"
              style={{ flexFlow: "column", marginTop: "20px" }}
            >
              {fields.map((field) => {
                return (
                  <field.component
                    key={field.name}
                    name={field.name}
                    className={field.className}
                    placeholder={field.placeholder}
                    InputProps={field.InputProps}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={field.data}
                  />
                );
              })}
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "20px" }}
                type="submit"
              >
                {t("loginForm.buttons.login")}
              </Button>
              {/* <LineThroughLabel
                text={t("loginForm.buttons.or")}
                style={{ margin: "10px 50px" }}
              /> */}
              {/* <Button
                color="primary"
                variant="outlined"
                className={classes.lightPrimaryButton}
                onClick={handleRegisterClick}
              >
                {t("loginForm.buttons.register")}
              </Button> */}
              {/* eslint-disable-next-line */}
              <label
                color="#7A7C83"
                style={{
                  marginTop: "20px",
                  cursor: "pointer",
                  color: "#7A7C83",
                }}
                onClick={() => navigateTo("forgot-password")}
              >
                {t("loginForm.buttons.forgotPassword")}
              </label>
            </Box>
          </div>
        </form>
      )}
    />
  );
}
