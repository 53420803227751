import React, { ReactElement, ReactNode, useMemo } from "react";
import { Form } from "react-final-form";
import { Box, Button, makeStyles } from "@material-ui/core";
import { makeValidate } from "mui-rff";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import jwt from "jwt-decode";
import { useCommonValidation } from "../utils/validators";
import PasswordInput from "../form/PasswordInput";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";
import { ResetPasswordContactContext } from "../contexts/ResetPasswordContactContext";
import { resetPassword } from "../api/vendor-core/v1";

const useStyles = makeStyles(() => ({
  input: {
    width: "300px",
    margin: "4px 0",
    minHeight: "50px",
  },
}));

export default function ResetPasswordForm(): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  useUpdateWindowHeight();
  const { strongPassword } = useCommonValidation();
  const { navigateTo } = useNavigation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { resetPasswordData } = React.useContext(ResetPasswordContactContext);
  const { access_token, sameOrigin } = resetPasswordData;
  const { callback } = jwt<any>(access_token);

  // eslint-disable-next-line consistent-return
  const handleSubmitForm = async ({ password: new_password }: any) => {
    try {
      const response = await resetPassword({
        new_password,
        ...resetPasswordData,
        token: resetPasswordData.access_token,
      });
      if (response.ok) {
        if (sameOrigin) {
          navigateTo("reset-success");
        } else {
          window.location.href = callback;
        }
      }
    } catch (e: any) {
      if (e?.response?.obj.detail) {
        return {
          confirmPassword: t(`serverValidation.${e?.response?.obj?.detail}`),
        };
      }
    }
  };

  const fields = [
    {
      name: "password",
      placeholder: "Нова лозинка",
    },
    {
      name: "confirmPassword",
      placeholder: "Потврди нова лозинка",
    },
  ];

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      password: strongPassword.required("Лозинаката е задолжителна"),
      confirmPassword: Yup.string().when("password", (password, schema) =>
        schema.test({
          test: (confirmPassword?: string) => confirmPassword === password,
          message: "Лозинките се различни",
        })
      ),
    });
  }, [strongPassword]);
  const validate = makeValidate(validationSchema);

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit }): ReactNode => (
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            style={{
              flexFlow: "column",
              alignItems: "center",
              minWidth: "300px",
            }}
          >
            <div style={{ margin: "20px 0", width: "100%" }}>
              <h3>{t("forgotPasswordForm.resetPassword.title")}</h3>
            </div>
            {fields.map((field) => {
              return (
                <PasswordInput
                  key={field.name}
                  name={field.name}
                  className={classes.input}
                  placeholder={field.placeholder}
                />
              );
            })}
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: "20px", width: "300px" }}
              onClick={handleSubmit}
            >
              {t("forgotPasswordForm.buttons.nextStep")}
            </Button>
            <span
              style={{
                marginTop: "20px",
                cursor: "pointer",
                color: "#7A7C83",
                maxWidth: "300px",
                alignSelf: "start",
              }}
              role="button"
              onClick={() => navigateTo("login")}
              tabIndex={0}
            >
              {t("backToLogin")}
            </span>
          </Box>
        </form>
      )}
    />
  );
}
