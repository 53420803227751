import React, { ReactNode, useMemo } from "react";
import { Form } from "react-final-form";
import { Box, Button } from "@material-ui/core";
import { makeValidate, Radios } from "mui-rff";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";

export default function ResetOptionForm(): React.ReactElement {
  const { t } = useTranslation();
  useUpdateWindowHeight();
  const { navigateTo } = useNavigation();
  useUpdateWindowHeight();
  const handleResetOptionSubmit = ({ resetOption }: any) => {
    if (resetOption === "email") {
      navigateTo("forgot-password/email");
    } else {
      navigateTo("forgot-password/phone");
    }
  };

  const schema = useMemo(() => {
    return Yup.object().shape({
      resetOption: Yup.string().required(
        t("forgotPasswordForm.validation.resetOption")
      ),
    });
  }, [t]);
  const validate = makeValidate(schema);

  return (
    <Form
      validate={validate}
      onSubmit={handleResetOptionSubmit}
      render={({ handleSubmit }): ReactNode => (
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            style={{
              flexFlow: "column",
              marginLeft: "40px",
              marginRight: "40px",
              minWidth: "300px",
            }}
          >
            <div style={{ margin: "20px 0", width: "100%" }}>
              <h3>{t("forgotPasswordForm.resetOption.title")}</h3>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label style={{ color: "#7A7C83" }}>
                {t("forgotPasswordForm.resetOption.subTitle")}
              </label>
            </div>
            <Radios
              name="resetOption"
              data={[
                {
                  label: t("forgotPasswordForm.resetOptionPhone"),
                  value: "phone",
                },
                {
                  label: t("forgotPasswordForm.resetOptionEmail"),
                  value: "email",
                },
              ]}
            />
            <Button
              color="primary"
              variant="outlined"
              style={{ marginTop: "20px", width: "300px" }}
              onClick={handleSubmit}
            >
              {t("forgotPasswordForm.buttons.nextStep")}
            </Button>
            <span
              style={{
                marginTop: "20px",
                cursor: "pointer",
                color: "#7A7C83",
                maxWidth: "300px",
                alignSelf: "start",
              }}
              role="button"
              onClick={() => navigateTo("login")}
              tabIndex={0}
            >
              {t("backToLogin")}
            </span>
          </Box>
        </form>
      )}
    />
  );
}
